<template>
	<div class="h-100">
		<div class="h-100 h-100 d-flex flex-column card-panel bg-white shadow-sm shadow-hover">
			<template v-if="isLoading">
				<BaseLoading />
			</template>

			<BaseModal
				v-if="this.expandirImagem"
				:telaInteira="false"
				@close="fecharImagem"
				:classesAdicionaisModal="'rounded position-relative'"
				:classesAdicionaisHeader="'d-none'"
				:classesAdicionaisFooter="'d-none'"
				:classesAdicionaisBody="'d-flex align-items-center'"
			>
				<div slot="body" class="container-fluid">
					<img class="img-fluid" :src="urlImagem" />
				</div>
			</BaseModal>

			<template>
				<div
					class="container-fluid overflow-overlay pb-2 px-0 m-0"
					id="execucaoOnline"
					:class="isMobile ? 'tamanho-card-mobile' : 'tamanho-card'"
				>
					<div class="row shadow-sm shadow-hover">
						<div class="col-12 pb-2">
							<span :class="isMobile ? 'h5 text-muted' : 'h1'"
								>Balanço da seção <strong>{{ nomeSecao }}</strong>
							</span>
						</div>
					</div>
					<div class="row mt-2 mx-0 align-items-end">
						<div class="col-10 p-0" v-if="dadosExecucaoSecaoBalanco.ControlaLocalizacao">
							<label class="mb-0"> Localização </label>
							<div class="input-group">
								<input
									placeholder="Código de Barras"
									class="form-control form-control-sm"
									type="text"
									v-model="siglaLocalizacao"
								/>
								<div class="input-group-append">
									<button
										v-if="siglaLocalizacao && siglaLocalizacao.length"
										class="btn-icon-xs btn-clear"
										@click="siglaLocalizacao = null"
										title="Limpar campo Localização"
									>
										<i class="icon icon-lx-close"></i>
									</button>
								</div>
							</div>
						</div>
						<div class="col-2 pr-0 pl-2" v-if="isMobile && dadosExecucaoSecaoBalanco.ControlaLocalizacao">
							<button class="btn btn-secondary form-control form-control-sm" @click="abrirLeitorCodigoBarras">
								<i class="fa fa-barcode" aria-hidden="true"></i>
							</button>
						</div>
					</div>
					<div class="row mt-2 mx-0 align-items-end">
						<div class="col-2 pr-2 pl-0">
							<label class="mb-0"> Qtde</label>
							<InputNumber
								v-model="produto.Quantidade"
								:disabled="requerLocalizacao"
								:precisao="casasDecimaisQtdeProduto"
								class="form-control form-control-sm"
							/>
						</div>
						<div class="col-8 p-0">
							<label class="mb-0"> Produto </label>
							<div class="input-group">
								<input
									:disabled="requerLocalizacao"
									placeholder="Código de Barras"
									@keyup.enter="buscarItem(produto.CodeBar)"
									class="form-control form-control-sm"
									type="text"
									v-model="produto.CodeBar"
								/>
								<div class="input-group-append">
									<button
										v-if="produto.CodeBar && produto.CodeBar.length"
										class="btn-icon-xs btn-clear"
										@click="produto.CodeBar = null"
										title="Limpar campo Produto"
									>
										<i class="icon icon-lx-close"></i>
									</button>
								</div>
								<div class="input-group-append">
									<button
										:disabled="requerLocalizacao"
										type="button"
										class="btn btn-sm btn-primary"
										@click="buscarItem(produto.CodeBar)"
									>
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</div>
						</div>
						<div class="col-2 pr-0 pl-2" v-if="isMobile">
							<button
								:disabled="requerLocalizacao"
								class="btn btn-secondary form-control form-control-sm"
								@click="abrirLeitorCodigoBarras"
							>
								<i class="fa fa-barcode" aria-hidden="true"></i>
							</button>
						</div>
					</div>
					<div class="row mt-2 mx-0 align-items-end">
						<p>
							TOTAL DE PRODUTOS: <b>{{ formatarValorTotal(totalizador().QuantidadeItens) }}</b>
						</p>
					</div>
					<div class="row flex-grow-1 mt-3 m-0 p-0">
						<div class="col-12 m-0 p-0">
							<div class="table-responsive mb-3" style="height: calc(100vh - 270px); overflow-y: auto">
								<table class="table table-sm table-striped table-hover" cellspacing="1">
									<thead>
										<tr>
											<th class="text-left text-nowrap">Produto</th>
											<template v-if="dadosExecucaoSecaoBalanco.ControlaLoteProduto">
												<th class="text-center text-nowrap">Lote</th>
												<th class="text-center text-nowrap">Data de Fabricação</th>
												<th class="text-center text-nowrap">Data de Validade</th>
											</template>
											<th class="text-left text-nowrap">Qtde</th>
											<th
												v-if="dadosExecucaoSecaoBalanco.ControlaLocalizacao && !isMobile"
												class="text-left text-nowrap"
											>
												Localização
											</th>
											<th class="text-left text-nowrap">Ações</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(produtoConferido, idx) in listaItensConferidos">
											<tr :key="idx">
												<td class="text-left fz-80" v-if="isMobile">
													{{ produtoConferido.CodigoProduto }}
													<span class="text-muted"> - {{ produtoConferido.Nome }} <br /> </span>
													<div v-if="dadosExecucaoSecaoBalanco.ControlaLocalizacao">
														<strong>Localização: </strong>
														<span class="text-muted">
															{{ produtoConferido.SiglaLocalizacao }}
														</span>
													</div>
												</td>
												<td class="text-left align-middle" v-else>
													{{ produtoConferido.CodigoProduto }} - {{ produtoConferido.Nome }}
												</td>
												<template v-if="dadosExecucaoSecaoBalanco.ControlaLoteProduto">
													<td class="text-center align-middle">
														<span
															v-if="
																dadosExecucaoSecaoBalanco.UploadArquivo && produtoConferido.Lote
															"
															>{{ produtoConferido.Lote }}</span
														>
														<span v-else-if="(produtoConferido.Lotes || []).length == 1">{{
															produtoConferido.Lotes[0].Lote
														}}</span>
														<span v-else>-</span>
													</td>
													<template v-if="(produtoConferido.Lotes || []).length == 1">
														<td class="text-center align-middle">
															{{ produtoConferido.Lotes[0].DataFabricacaoFormatada }}
														</td>
														<td class="text-center align-middle">
															{{ produtoConferido.Lotes[0].DataVencimentoFormatada }}
														</td>
													</template>
													<template v-else>
														<td class="text-center align-middle">
															{{ produtoConferido.DataFabricacaoFormatada }}
														</td>
														<td class="text-center align-middle">
															{{ produtoConferido.DataVencimentoFormatada }}
														</td>
													</template>
												</template>
												<td class="text-left align-middle">
													{{ formatarValorQtde(produtoConferido.Quantidade) }}
												</td>
												<td
													class="text-left align-middle"
													v-if="dadosExecucaoSecaoBalanco.ControlaLocalizacao && !isMobile"
												>
													{{ produtoConferido.SiglaLocalizacao }}
												</td>
												<td colspan="1" class="text-left text-nowrap align-middle">
													<button
														class="btn btn-danger mr-1"
														@click="onExcluirProduto(produtoConferido)"
														v-tooltip="apresentarTooltip('Excluir')"
													>
														<i class="fas fa-trash-alt"></i>
													</button>
													<button
														v-if="produtoPossuiMaisDeUmLote(produtoConferido)"
														class="btn btn-primary mr-1"
														@click="onVisualizarLotesProduto(produtoConferido, idx)"
														v-tooltip="apresentarTooltip('Visualizar lotes')"
													>
														<i class="fas fa-box-open"></i>
													</button>
													<button
														class="btn btn-primary mr-1"
														@click="onVisualizarImagemProduto(produtoConferido, idx)"
														v-tooltip="apresentarTooltip('Visualizar Imagem')"
														:disabled="!produtoConferido.PossuiImagem"
													>
														<i class="fas fa-image"></i>
													</button>
												</td>
											</tr>
											<template v-if="produtoConferido.ExibirLotes">
												<tr
													:key="'lote_' + indexLote + '_' + produtoConferido.CodigoProduto"
													v-for="(dadosLote, indexLote) in produtoConferido.Lotes"
												>
													<td>
														<div v-if="indexLote == 0" class="invalid-feedback d-block">
															<span
																v-html="errosValidacao[getIdLote(produtoConferido.CodigoProduto)]"
															></span>
														</div>
													</td>
													<td class="text-center align-middle">{{ dadosLote.Lote }}</td>
													<td class="text-center align-middle">{{
														dadosLote.DataFabricacaoFormatada
													}}</td>
													<td class="text-center align-middle">{{
														dadosLote.DataVencimentoFormatada
													}}</td>
													<td class="text-center align-middle">
														<InputNumber
															v-model="dadosLote.Quantidade"
															:disabled="requerLocalizacao"
															:precisao="casasDecimaisQtdeProduto"
															:valorMinimo="0"
															class="form-control form-control-sm"
															style="max-width: 110px"
														/>
													</td>
													<td v-if="dadosExecucaoSecaoBalanco.ControlaLocalizacao && !isMobile"></td>
													<td></td>
												</tr>
											</template>
											<tr :key="'_' + idx" v-if="produtoConferido.ExibirImagem">
												<td colspan="10">
													<div
														v-if="produtoConferido.ExibirImagem && loadingImage"
														class="d-flex justify-content-center"
													>
														<i class="fas fa-circle-notch fa-spin fa-2x fa-fw"></i>
													</div>
													<div class="d-flex justify-content-center">
														<img
															:src="imagensDict[produtoConferido.CodigoProduto]"
															height="200px"
															@click="abrirImagem(produtoConferido.CodigoProduto)"
														/>
													</div>
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<LeitorCodigoBarras v-model="leitorCodigoBarras.retorno" :ativo="leitorCodigoBarras.ativo" />
				<audio ref="beep" src="./../../../common/audio/beep.mp3"></audio>
				<audio ref="beepErro" src="./../../../common/audio/beepErro.mp3"></audio>
			</template>
		</div>

		<div class="shadow-sm card-panel d-flex align-items-center detalhes-footer fixed-bottom">
			<div class="d-flex justify-content-between w-100">
				<button
					id="lmxta-dadosadicionais-btn-cancelar"
					type="button"
					class="btn btn-default rounded"
					tabindex="8"
					@click="redirecionarPagina()"
				>
					<span class="text-center">Fechar</span>
				</button>
				<div>
					<span class="text-uppercase small" style="cursor: default">
						{{ totalizador().Inseridos }} itens inseridos |
						{{ formatarValorTotal(totalizador().QuantidadeItens) }} unidades
					</span>
				</div>
				<button
					id="lmxta-dadosadicionais-btn-salvar"
					type="button"
					class="btn btn-primary rounded"
					tabindex="8"
					@click="finalizarExecucaoSecao()"
				>
					<span class="text-center">Finalizar</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import swal from '@/common/alerts';
	import loading from '@/common/loading';
	import LeitorCodigoBarras from '@/components/shared/LeitorCodigoBarras.vue';
	import InputNumber from '@/components/shared/input/InputNumber.vue';
	import { mapGetters } from 'vuex';
	import VTooltip from 'v-tooltip';
	import Vue from 'vue';

	Vue.use(VTooltip);

	export default {
		components: {
			LeitorCodigoBarras,
			InputNumber,
		},
		data() {
			return {
				errosValidacao: {},
				idSecao: '',
				nomeSecao: '',
				idBalanco: '',
				etapa: '',
				token: '',
				urlApi: '',
				siglaLocalizacao: '',
				produto: {
					NomeProduto: '',
					Quantidade: 1,
					CodeBar: '',
					CodigoProduto: null,
					Lote: null,
				},
				ativo: false,
				leitorCodigoBarras: {
					ativo: false,
					retorno: null,
				},
				dadosExecucaoSecaoBalanco: {
					PermiteFinalizar: false,
					ControlaLocalizacao: false,
					ControlaLoteProduto: false,
					Itens: [],
				},
				imagensDict: {},
				urlImagem: '',
				expandirImagem: false,
				loadingImage: false,
				listaItensConferidosBefore: [],
			};
		},
		created() {
			var self = this;
			self.nomeSecao = self.$route.query.nomeSecao;
			self.idSecao = self.$route.query.idSessao;
			self.idBalanco = self.$route.query.idBalanco;
			self.etapa = self.$route.query.etapa;
			self.token = self.$route.query.token;
			self.urlApi = self.$route.query.urlApi;
			self.listaItensConferidosBefore = JSON.parse(JSON.stringify(self.listaItensConferidos));
		},
		beforeMount() {
			this.iniciarExecucaoSecaoBalanco();
		},
		watch: {
			'leitorCodigoBarras.retorno': function (result) {
				if (this.leitorCodigoBarras.ativo) {
					this.leitorCodigoBarras.ativo = false;
				}

				if (result && result.codigoBarras) {
					this.produto.CodeBar = result.codigoBarras;
					this.buscarItem(result.codigoBarras, true);
				}
			},
		},
		computed: {
			...mapGetters({
				listaItensConferidos: 'balanco/listaItensConferidos',
			}),
			isLoading() {
				return this.$store.state.uiState.isLoading;
			},
			isMobile() {
				if (navigator.userAgent.indexOf('Mobile') > 0) {
					return true;
				} else {
					return false;
				}
			},
			requerLocalizacao() {
				return this.dadosExecucaoSecaoBalanco.ControlaLocalizacao && !this.siglaLocalizacao;
			},
			casasDecimaisQtdeProduto() {
				return this.$store.state.balanco.parametroCasasDecimaisQuantidadeProduto || 0;
			},
			bootstrapConflict() {
				return {
					template:
						'<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
					arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
					innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
				};
			},
		},
		methods: {
			totalizador() {
				let totalizador = {
					Inseridos: 0,
					QuantidadeItens: 0,
				};
				var lista = this.listaItensConferidos;

				lista?.forEach((item) => {
					totalizador.Inseridos++;
					totalizador.QuantidadeItens += item.Quantidade;
				});

				return totalizador;
			},
			apresentarTooltip(mensagem) {
				return { content: mensagem, ...this.bootstrapConflict };
			},
			abrirImagem: function (codigoProduto) {
				this.urlImagem = this.imagensDict[codigoProduto];
				this.expandirImagem = true;
			},
			fecharImagem: function () {
				this.expandirImagem = false;
				this.$emit('fechar');
			},
			async atualizarItem(produto, index) {
				let commandItem = {
					CodigoProduto: produto.CodigoProduto,
					IdBalanco: this.idBalanco,
					IdSecao: this.idSecao,
					Etapa: this.etapa,
					Lotes: produto.Lotes.filter((l) => l.Quantidade > 0),
				};

				await this.$store.dispatch('balanco/atualizarConferenciaItemSecao', {
					index,
					produto,
					commandItem,
				});
			},
			async buscarItem(codebar, ehLeituraCodigoBarras) {
				var self = this;
				self.leitorCodigoBarras.retorno = null;

				if (this.requerLocalizacao) {
					swal.exibirMensagem('Favor informar a localização do produto');
					return;
				}
				if (codebar == '') {
					swal.exibirMensagem('Informe um código de barras');
					return;
				}

				codebar = codebar.trim();
				var command = {
					IdBalanco: self.idBalanco,
					IdSecao: self.idSecao,
					CodigoBarras: codebar,
				};

				loading.exibir();
				self.$store
					.dispatch('balanco/validarCodigoDeBarras', command)
					.then(() => {
						var produtoNovo = self.$store.state.balanco.produto;
						var quantidade = self.produto.Quantidade || 1;
						quantidade = parseFloat(quantidade);

						// MODAJOI-22173
						// Chamar API para conferir item a cada bipagem
						let commandItens = {
							IdBalanco: self.idBalanco,
							IdSecao: self.idSecao,
							Etapa: self.etapa,
							CodigoBarras: codebar,
							Quantidade: parseFloat(quantidade.toString().replace(',', '.')).toFixed(
								self.casasDecimaisQtdeProduto,
							),
							SiglaLocalizacao: self.siglaLocalizacao,
						};

						self.$store
							.dispatch('balanco/conferirExecucaoItemSecao', commandItens)
							.then((response) => {
								if (produtoNovo != null && produtoNovo != undefined) {
									self.$refs.beep.play();

									produtoNovo.Quantidade = parseFloat(response.Quantidade);
									produtoNovo.CodeBar = response.CodigoBarras;
									produtoNovo.SiglaLocalizacao = response.SiglaLocalizacao;
									produtoNovo.Nome = response.Nome;
									produtoNovo.CodigoProduto = response.CodigoProduto;
									produtoNovo.IdProdutoLocalizacaoDeposito = response.IdProdutoLocalizacaoDeposito;
									produtoNovo.Lotes = response.Lotes || [];
									produtoNovo.DataFabricacao = response.DataFabricacao;
									produtoNovo.DataVencimento = response.DataVencimento;
									produtoNovo.IdLote = response.IdLote;
									produtoNovo.ExibirImagem = false;
									produtoNovo.PossuiImagem = response.PossuiImagem;

									var produtos = self.listaItensConferidos;
									if (produtos) {
										var produtoExiste = produtos.find(function (p) {
											if (
												p.CodigoProduto == response.CodigoProduto &&
												p.IdProdutoLocalizacaoDeposito == response.IdProdutoLocalizacaoDeposito
											) {
												p.Quantidade += parseFloat(quantidade.toFixed(self.casasDecimaisQtdeProduto));
												return true;
											}
										});

										if (!produtoExiste) {
											produtos.unshift(produtoNovo);
										}
										self.$store.dispatch('balanco/atualizarListaItensConferidos', produtos);
										self.totalizador();
										self.limparCampos();
										if (ehLeituraCodigoBarras) self.abrirLeitorCodigoBarras();
									}
								} else {
									swal.exibirMensagemErro('Ocorreu um problema');
								}
							})
							.catch((erro) => {
								self.$refs.beepErro.play();
								let mensagemErro = '';

								if (erro.response) {
									if (erro.response.status == 400) mensagemErro = erro.data;
									else mensagemErro = erro.message;
								} else mensagemErro = erro;

								swal.exibirMensagemErro(mensagemErro);
								return Promise.reject(mensagemErro);
							})
							.finally(function () {
								loading.ocultar();
							});
					})
					.catch((erro) => {
						loading.ocultar();
						self.$refs.beepErro.play();
						if (ehLeituraCodigoBarras) {
							swal.exibirMensagem(
								`${erro} Tente inserir manualmente.`,
							);
						} else {
							swal.exibirMensagem(
								`${erro}`,
							);
						}
						self.limparCampos();
						return Promise.reject(erro);
					});
			},
			onExcluirProduto(produto) {
				swal.exibirPergunta(
					'Excluir Item',
					`Deseja excluir o item:<br> ${produto.Nome}<br> da lista de conferência?`,
					async (response) => {
						if (response) {
							this.excluirProduto(produto);
						}
					},
				);
			},
			excluirProduto(produto) {
				var self = this;
				let command = {
					IdBalanco: self.idBalanco,
					IdSecao: self.idSecao,
					Etapa: self.etapa,
					CodigoProduto: produto.CodigoProduto,
					IdProdutoLocalizacaoDeposito: produto.IdProdutoLocalizacaoDeposito,
				};
				loading.exibir();
				self.$store
					.dispatch('balanco/excluirConferenciaExecucaoItemSecao', command)
					.then(() => {
						let produtos = self.listaItensConferidos?.filter((item) => {
							return !(
								item.CodigoProduto == produto.CodigoProduto &&
								item.IdProdutoLocalizacaoDeposito == produto.IdProdutoLocalizacaoDeposito
							);
						});

						if (produtos) {
							self.$store.dispatch('balanco/atualizarListaItensConferidos', produtos);
							swal.exibirToastSucesso('Produto excluído!');
						}
					})
					.catch((erro) => {
						self.$refs.beepErro.play();
						let mensagemErro = '';

						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						swal.exibirMensagemErro(mensagemErro);
						return Promise.reject(mensagemErro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},
			onVisualizarImagemProduto(produto, index) {
				const listaProdutos = this.listaItensConferidos;
				listaProdutos.forEach((p, idx) => {
					if (idx == index) p.ExibirImagem = !produto.ExibirImagem;
					else p.ExibirImagem = false;
				});
				this.$store.dispatch('balanco/atualizarListaItensConferidos', listaProdutos);

				if (!this.imagensDict[produto.CodigoProduto]) {
					this.loadingImage = true;
					var codigoProduto = produto.CodigoProduto;
					this.$store
						.dispatch('balanco/obterImagemProduto', { codigoProduto })
						.then((blobDaImagem) => {
							if (blobDaImagem.size > 0)
								Vue.set(this.imagensDict, produto.CodigoProduto, URL.createObjectURL(blobDaImagem));
						})
						.finally(() => {
							this.loadingImage = false;
						});
				}
			},
			limparCampos() {
				var self = this;
				self.produto.CodeBar = '';
				self.produto.Quantidade = 1;
			},
			async finalizarExecucaoSecao(confirmaDivergentes) {
				var self = this;
				confirmaDivergentes = confirmaDivergentes || false;
				
				const isLotesValidos = await self.validarQuantidadeLotes();
				if (!isLotesValidos) return;

				loading.exibir();

				const command = {
					IdBalanco: self.idBalanco,
					IdSecao: self.idSecao,
					Etapa: self.etapa,
					ValidarDivergentes: self.etapa === '3' && !confirmaDivergentes,
				};
				self.$store
					.dispatch('balanco/finalizarExecucaoSecao', command)
					.then(() => {
						swal.exibirMensagemSucesso('Seção finalizada com sucesso', () => {
							this.redirecionarPagina();
						});
						return Promise.resolve();
					})
					.catch(function (erro) {
						if (erro.response?.status == 400) {
							if (
								command.ValidarDivergentes &&
								erro.response.data.length == 1 &&
								erro.response.data[0].startsWith('Existem itens')
							) {
								swal.exibirMensagemComPergunta('Confirmação', erro.response.data + '<br>Confirma?', (sim) => {
									if (sim) self.finalizarExecucaoSecao(true);
								});
							} else {
								swal.exibirMensagemErro(erro);
							}
						} else if (typeof erro == 'string') {
							swal.exibirMensagemErro(erro);
						} else {
							swal.exibirMensagemErro('Ocorreu um problema');
						}
						return Promise.reject(erro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},
			iniciarExecucaoSecaoBalanco() {
				var self = this;
				if (!this.isLoading) {
					let command = {
						IdBalanco: self.idBalanco,
						IdSecao: self.idSecao,
						Etapa: self.etapa,
					};

					loading.exibir();
					return self.$store
						.dispatch('balanco/iniciarExecucaoSecaoBalanco', command)
						.then((response) => {
							this.dadosExecucaoSecaoBalanco = response;
							self.$store.dispatch('balanco/atualizarListaItensConferidos', response.Itens);
							return Promise.resolve();
						})
						.catch(function (erro) {
							let mensagemErro = '';

							if (erro.response) {
								if (erro.response.status == 400) mensagemErro = erro.data;
								else mensagemErro = erro.message;
							} else mensagemErro = erro;

							swal.exibirMensagemErro(mensagemErro, self.redirecionarPagina);
							return Promise.reject(mensagemErro);
						})
						.finally(function () {
							loading.ocultar();
						});
				} else {
					swal.exibirMensagemErro('Ocorreu um problema.');
				}
			},
			redirecionarPagina() {
				if (this.isMobile) {
					window.top.close();
					setTimeout(() => (window.location.href = 'https://erp.microvix.com.br/'), 3000);
				} else {
					this.$router.push({ name: 'PainelBalancos' });
				}
			},
			abrirLeitorCodigoBarras() {
				this.leitorCodigoBarras.ativo = true;
			},
			customLabel({ Lote }) {
				return Lote;
			},
			formatarValorTotal(value) {
				var valor = parseFloat(value).toFixed(this.casasDecimaisQtdeProduto);
				return this.formatarValorQtde(valor);
			},
			formatarValorQtde(value) {
				var self = this;
				return new Intl.NumberFormat('pt-BR', {
					maximumFractionDigits: self.casasDecimaisQtdeProduto,
					roundingMode: 'trunc',
				}).format(parseFloat(value));
			},
			produtoPossuiMaisDeUmLote(produto) {
				return this.dadosExecucaoSecaoBalanco.ControlaLoteProduto && (produto.Lotes || []).length > 1;
			},
			onVisualizarLotesProduto(produto, index) {
				produto.ExibirLotes = !produto.ExibirLotes;
				this.$store.dispatch('balanco/atualizarListaItensConferidos', this.listaItensConferidos);
			},
			async validarQuantidadeLotes() {
				var self = this;

				let possuiErroValidacaoLote = false;
				let index = 0;
				for (const produto of self.listaItensConferidos) {
					if (produto.Lotes.length > 1) {
						const quantidadeTotalLotes = produto.Lotes.reduce(
							(acc, curr) => (acc += Number(curr.Quantidade)),
							0,
						).toFixed(3);

						let erroValidacao = '';
						if (quantidadeTotalLotes < produto.Quantidade) erroValidacao = 'menor';
						if (quantidadeTotalLotes > produto.Quantidade) erroValidacao = 'maior';

						if (erroValidacao) {
							possuiErroValidacaoLote = true;
							self.errosValidacao[self.getIdLote(produto.CodigoProduto)] =
								'Quantidade informada dos lotes é ' +
								erroValidacao +
								' que a quantidade total de itens do produto.';
							produto.ExibirLotes = true;
						} else {
							self.errosValidacao[self.getIdLote(produto.CodigoProduto)] = '';
						}
					} else if (produto.Lotes.length == 1) {
						produto.Lotes[0].Quantidade = produto.Quantidade;
					}

					let produtoBefore = self.listaItensConferidosBefore.find((x) => x.CodigoProduto == produto.CodigoProduto);
					if (self.quantidadeFoiAlterada(produtoBefore, produto)) await self.atualizarItem(produto, index);

					index++;
				}

				self.listaItensConferidosBefore = JSON.parse(JSON.stringify(self.listaItensConferidos));
				return !possuiErroValidacaoLote;
			},
			getIdLote(codigoProduto) {
				return 'lote-' + codigoProduto;
			},
			quantidadeFoiAlterada(produtoBefore, produto) {
				if (!produtoBefore && produto.Lotes) return true;

				if (produtoBefore.Quantidade != produto.Quantidade) return true;

				let quantidadeAlterada = false;
				let indice = 0;

				for (const lote of produtoBefore.Lotes) {
					if (lote.Quantidade != produto.Lotes[indice].Quantidade) quantidadeAlterada = true;
					indice++;
				}

				return quantidadeAlterada;
			},
		},
	};
</script>

<style scoped>
	.tamanho-card-mobile {
		height: calc(100% - 100px);
	}

	.tamanho-card {
		height: 100%;
	}
	.btn-clear {
		color: #ee9ca4;
		background-color: #fff;
		border-color: #f3828e;
	}
	tr > td:nth-child(2) {
		width: 15rem;
	}
</style>
